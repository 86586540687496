import axios from 'axios';
import axiosInstance from '../utils/axiosConfig';
import qs from 'qs';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Function to get CSRF token from cookies
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}


// Add the new ensureCsrfToken function
const ensureCsrfToken = async () => {
  try {
    await axiosInstance.get('https://api.yzfdev.com/accounts/csrf_cookie/');
    // Wait a brief moment for the cookie to be set
    await new Promise(resolve => setTimeout(resolve, 100));
    
    const token = getCookie('csrftoken');
    if (!token) {
      throw new Error('Failed to get CSRF token');
    }
    
    return token;
  } catch (error) {
    console.error('Error getting CSRF token:', error);
    throw error;
  }
};

// Set up axios to always send the CSRF token
axios.interceptors.request.use(function (config) {
  config.headers['X-CSRFToken'] = getCookie('csrftoken');
  return config;
});


const login = async (email, password) => {
  console.log('Attempting login with:', { email, password: '********' });
  try {
    const csrfToken = await axiosInstance.getCsrfToken();
    console.log('Using CSRF token:', csrfToken);

    const response = await axios.post(
      'https://api.yzfdev.com/accounts/login/',
      qs.stringify({
        login: email,
        password: password,
      }),
      {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
        },
        withCredentials: true,
      }
    );

    console.log('Login response from server:', response.data);

    if (response.data.success) {
      const userData = response.data.user;
      localStorage.setItem('user', JSON.stringify(userData));
      
      return {
        user: userData,
        success: true
      };
    }
    
    throw new Error('Login failed: ' + (response.data.error || 'Unknown error'));
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Keep all other existing functions exactly as they are
const register = (username, email, password, userType) => {
  return axios.post(`https://api.yzfdev.com/accounts/signup/`, {
    username,
    email,
    password1: password,
    password2: password,
    user_type: userType,
  });
};

const logout = async () => {
  try {
    await axios.post(`https://api.yzfdev.com/accounts/logout/`);
    localStorage.removeItem('user');
  } catch (error) {
    console.error('Logout error:', error);
  }
};

const getCurrentUser = () => {
  try {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      const user = JSON.parse(userStr);
      if (user && user.user_type) {
        return user;
      }
    }
    return null;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};

const authService = {
  register,
  login,
  logout,
  getCurrentUser,
  ensureCsrfToken, // Add the new function to the service
};

export default authService;