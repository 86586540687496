import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.yzfdev.com',
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
  },
});


axiosInstance.interceptors.request.use(
  async config => {

    const now = Date.now();
    const timeSinceLastRequest = now - lastRequestTime;
    
    if (timeSinceLastRequest < minRequestInterval) {
      await new Promise(resolve => setTimeout(resolve, minRequestInterval - timeSinceLastRequest));
    }
    lastRequestTime = Date.now();


    let token = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    

    if (!token && !config.url.endsWith('https://api.yzfdev.com/csrf_cookie/')) {
      try {
        await axiosInstance.get('https://api.yzfdev.com/accounts/csrf_cookie/');
       
        await new Promise(resolve => setTimeout(resolve, 300));
        token = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
      } catch (err) {
        console.warn('Failed to fetch CSRF token:', err);
      }
    }

    if (token) {
      config.headers['X-CSRFToken'] = token;
    }
    
    console.log('Request URL:', config.url);
    console.log('CSRF Token:', token);
    console.log('Headers:', config.headers);
    
    return config;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

let lastRequestTime = Date.now();
const minRequestInterval = 2000; 


axiosInstance.interceptors.response.use(
  response => {
    console.log('Response:', {
      url: response.config.url,
      status: response.status,
      headers: response.headers,
      data: response.data
    });
    return response;
  },
  async error => {
    console.error('Response error:', {
      url: error.config?.url,
      status: error.response?.status,
      headers: error.response?.headers,
      data: error.response?.data
    });

    // Handle rate limiting
    if (error.response?.status === 429) {
      const retryAfter = error.response.headers['retry-after'] || 3;
      await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));
      return axiosInstance(error.config);
    }
    
    return Promise.reject(error);
  }
);


axiosInstance.getCsrfToken = async () => {
  try {
    const response = await axiosInstance.get('https://api.yzfdev.com/accounts/csrf_cookie/');
    
    await new Promise(resolve => setTimeout(resolve, 300));
    
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
      
    if (!token) {
      throw new Error('CSRF token not set in cookies after request');
    }
    
    return token;
  } catch (error) {
    console.error('Error getting CSRF token:', error);
    throw error;
  }
};

export default axiosInstance;