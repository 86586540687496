import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import Login from './components/Login';
import Register from './components/Register';
import InternalDashboard from './components/InternalDashboard.js';
import ExternalDashboard from './components/ExternalDashboard.js';
import HomePage from './pages/HomePage';
import LinkedInScraper from './pages/LinkedInScraper';
import ErrorBoundary from './ErrorBoundary';
import authService from './services/auth';
import logo from './assets/logo-2.png'; 

const PrivateRoute = ({ children, allowedUserType }) => {
  const user = authService.getCurrentUser();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (allowedUserType && user.user_type !== allowedUserType) {
    return <Navigate to={user.user_type === 'internal' ? '/internal-dashboard' : '/external-dashboard'} />;
  }

  return children;
};

const NavigationBar = ({ user, loading, handleLogout }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  if (isLoginPage) {
    return null;
  }

  return (
    <AppBar 
      position="fixed" 
      elevation={0} 
      sx={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(8px)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
        padding: '0.75rem 0'
      }}
    >
      <Container maxWidth="lg">
        <Toolbar 
          sx={{ 
            padding: '0 !important',
            minHeight: '70px'
          }}
        >
          <Box
            component={Link}
            to={user?.user_type === 'internal' ? '/internal-dashboard' : '/external-dashboard'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              gap: 3
            }}
          >
            <img 
              src={logo} 
              alt="YZF Logo" 
              style={{ 
                height: '60px',
                width: 'auto',
                objectFit: 'contain'
              }} 
            />
            <Typography 
              variant="h5" 
              sx={{ 
                fontFamily: '"Inter", "Roboto", sans-serif',
                fontWeight: 800,
                fontSize: '2rem',
                letterSpacing: '-0.02em',
                color: '#2B3674',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '-4px',
                  left: '0',
                  width: '100%',
                  height: '2px',
                  background: 'linear-gradient(90deg, #2B3674 0%, rgba(43, 54, 116, 0) 100%)',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                },
                '&:hover::after': {
                  opacity: 1,
                }
              }}
            >
              YZFApp
            </Typography>
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />
          
          {!loading && user && (
            <Button 
              onClick={handleLogout}
              sx={{ 
                color: '#2B3674',
                borderRadius: '14px',
                padding: '10px 28px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 600,
                backgroundColor: 'rgba(43, 54, 116, 0.05)',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: 'rgba(43, 54, 116, 0.1)',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 4px 12px rgba(43, 54, 116, 0.08)'
                }
              }}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = authService.getCurrentUser();
        setUser(currentUser);
      } finally {
        setLoading(false);
      }
    };
    checkUser();
  }, []);

  const handleLogout = async () => {
    await authService.logout();
    setUser(null);
    window.location.href = '/login';
  };

  return (
    <Router>
      <ErrorBoundary>
        <Box sx={{ 
          minHeight: '100vh',
          bgcolor: 'background.default'
        }}>
          <NavigationBar 
            user={user} 
            loading={loading} 
            handleLogout={handleLogout}
          />
          <Box sx={{ pt: '80px' }}>  
            <Routes>
              <Route path="/login" element={<Login setUser={setUser} />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/internal-dashboard"
                element={
                  <PrivateRoute allowedUserType="internal">
                    <InternalDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/external-dashboard"
                element={
                  <PrivateRoute allowedUserType="external">
                    <ExternalDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/company-search"
                element={
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/linkedin-scraper"
                element={
                  <PrivateRoute allowedUserType="internal">
                    <LinkedInScraper />
                  </PrivateRoute>
                }
              />
              <Route path="/" element={
              user ? (
                <Navigate to={user.user_type === 'internal' ? '/internal-dashboard' : '/external-dashboard'} />
              ) : (
                <Navigate to="/login" />
              )
            } />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          </Box>
        </Box>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
