import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2B3674',
      dark: '#1a2147',
    },
    secondary: {
      main: '#557A59',
      dark: '#456148',
      light: '#789C7C',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    }
  },
  typography: {
    h4: {
      fontWeight: 700,
      color: '#2B3674',
      fontSize: '2.25rem',
      '@media (max-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          transition: 'all 0.2s ease',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'all 0.2s ease',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          transition: 'transform 0.2s ease',
          '&:focus-within': {
            transform: 'translateY(-1px)',
          },
        },
      },
    },
  },
});

export default theme;
