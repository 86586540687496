import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box, List, ListItem, CircularProgress } from '@mui/material';
import { Search as SearchIcon, LinkedIn as LinkedInIcon } from '@mui/icons-material';
import authService from '../services/auth';
import theme from '../theme';

const InternalDashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('InternalDashboard mounted');
    const checkUser = async () => {
      try {
        const userData = authService.getCurrentUser();
        console.log('Current user data:', userData);
        
        if (userData && userData.user_type === 'internal') {
          setUser(userData);
        } else {
          console.log('Invalid user type or no user, redirecting to login');
          await authService.logout();
          navigate('/login', { replace: true });
        }
      } catch (error) {
        console.error('Dashboard access error:', error);
        navigate('/login', { replace: true });
      } finally {
        setLoading(false);
      }
    };

    checkUser();
  }, [navigate]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    console.log('No user data, returning null');
    return null;
  }

  console.log('Rendering dashboard for user:', user);
  return (
    <Container maxWidth="md">
      <Box sx={{ 
        mt: { xs: 4, md: 6 }, 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 1,
        transform: 'translateY(0)',
        animation: 'fadeIn 0.3s ease-out',
        '@keyframes fadeIn': {
          from: {
            opacity: 0,
            transform: 'translateY(10px)'
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)'
          }
        }
      }}>
        <Box sx={{
          mb: 6,
          textAlign: 'center'
        }}>
          <Typography 
            variant="h4" 
            component="h1" 
            gutterBottom 
            sx={{
              fontSize: { xs: '1.75rem', md: '2.25rem' },
              fontWeight: 700,
              color: '#2B3674',
              mb: 1
            }}
          >
            Welcome back
          </Typography>
          <Typography 
            variant="h6" 
            sx={{
              color: 'text.secondary',
              fontWeight: 500,
              fontSize: '1.1rem'
            }}
          >
            {user?.username}
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: 4,
          width: '100%',
          maxWidth: 900,
          mt: 2
        }}>
          <Button
            component={Link}
            to="/company-search"
            variant="contained"
            sx={{
              p: 6,
              borderRadius: '24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
              backgroundColor: theme.palette.primary.main,
              transition: 'all 0.2s ease',
              boxShadow: '0 4px 18px rgba(43, 54, 116, 0.15)',
              '&:hover': {
                transform: 'translateY(-3px)',
                backgroundColor: theme.palette.primary.dark,
                boxShadow: '0 6px 20px rgba(43, 54, 116, 0.25)'
              }
            }}
          >
            <SearchIcon sx={{ fontSize: 70, mb: 1 }} />
            <Box sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 1,
                  fontWeight: 600,
                  fontSize: '1.5rem'
                }}
              >
                Company Database
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontSize: '1rem'
                }}
              >
                Search and analyze company information
              </Typography>
            </Box>
          </Button>

          <Button
            component={Link}
            to="/linkedin-scraper"
            variant="contained"
            sx={{
              p: 6,
              borderRadius: '24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
              backgroundColor: theme.palette.secondary.main,
              transition: 'all 0.2s ease',
              boxShadow: '0 4px 18px rgba(85, 122, 89, 0.15)',
              '&:hover': {
                transform: 'translateY(-3px)',
                backgroundColor: theme.palette.secondary.dark,
                boxShadow: '0 6px 20px rgba(85, 122, 89, 0.25)'
              }
            }}
          >
            <LinkedInIcon sx={{ fontSize: 70, mb: 1 }} />
            <Box sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 1,
                  fontWeight: 600,
                  fontSize: '1.5rem'
                }}
              >
                Data Enrichment
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontSize: '1rem'
                }}
              >
                Enhance company profiles with LinkedIn data
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default InternalDashboard;
