import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import authService from '../services/auth';
import { TextField, Button, Typography, Container, Box, Alert } from '@mui/material';
import logo from '../assets/logo-2.png';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch CSRF token when component mounts
    const getCsrfToken = async () => {
      try {
        await authService.ensureCsrfToken();  // Use the service method instead
      } catch (error) {
        console.error('Error setting CSRF token:', error);
        setError('Failed to initialize security token. Please refresh the page.');
      }
    };
    getCsrfToken();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.login(email, password);
      if (response.user) {
        setUser(response.user);
        // Determine redirect path based on user type
        const redirectPath = response.user.user_type === 'internal' 
          ? '/internal-dashboard' 
          : '/external-dashboard';
        
        console.log('Login successful, redirecting to:', redirectPath);
        navigate(redirectPath, { replace: true });
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'An error occurred during login.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: { xs: 4, md: 8 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box 
          sx={{ 
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2
          }}
        >
          <img 
            src={logo} 
            alt="YZF Logo" 
            style={{ 
              height: '80px',
              width: 'auto',
              objectFit: 'contain'
            }} 
          />
          <Typography 
            variant="h5" 
            sx={{ 
              fontFamily: '"Inter", "Roboto", sans-serif',
              fontWeight: 700,
              color: '#2B3674',
              letterSpacing: '-0.02em'
            }}
          >
            Welcome to YZFApp
          </Typography>
        </Box>

        <Box 
          component="form" 
          onSubmit={handleLogin} 
          noValidate 
          sx={{ 
            width: '100%',
            maxWidth: 400,
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
            p: 4
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiInputLabel-root': {
                transform: 'translate(14px, -9px) scale(0.75)',
                backgroundColor: 'white',
                px: 1,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                transform: 'translate(14px, -9px) scale(0.75)',
              },
              '& .MuiInputBase-root': {
                backgroundColor: 'white',
              }
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiInputLabel-root': {
                transform: 'translate(14px, -9px) scale(0.75)',
                backgroundColor: 'white',
                px: 1,
              },
              '& .MuiInputLabel-root.Mui-focused': {
                transform: 'translate(14px, -9px) scale(0.75)',
              },
              '& .MuiInputBase-root': {
                backgroundColor: 'white',
              }
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 3, 
              mb: 2,
              py: 1.5,
              backgroundColor: '#2B3674',
              borderRadius: '12px',
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: '#1a2147',
                transform: 'translateY(-1px)',
                boxShadow: '0 4px 12px rgba(43, 54, 116, 0.15)'
              }
            }}
          >
            Sign In
          </Button>
          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mt: 2,
                borderRadius: 2
              }}
            >
              {error}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
