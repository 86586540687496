import React, { useState, useCallback, useRef, useEffect } from 'react';
import { 
  Container, Typography, TextField, List, ListItem, 
  ListItemText, Dialog, DialogTitle, DialogContent, DialogActions,
  CircularProgress, Button, Box, Paper, InputBase, Alert, Fade, Slide
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import theme from '../theme';
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const lastRequestTime = useRef(0);
  const minRequestInterval = 2000;
  const searchTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const performSearch = async (query) => {
    if (!query.trim() || query.length < 2) {
      setSearchResults([]);
      return;
    }

    const now = Date.now();
    if (now - lastRequestTime.current < minRequestInterval) {
      console.log('Request throttled');
      // Schedule a retry after the interval
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => performSearch(query), minRequestInterval);
      return;
    }

    setIsLoading(true);
    setError(null);
    lastRequestTime.current = now;

    try {
      const response = await axiosInstance.get(`https://api.yzfdev.com/companies/search/?q=${encodeURIComponent(query)}`);
      if (Array.isArray(response.data)) {
        setSearchResults(response.data);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (err) {
      if (err.response?.status === 429) {
        setError('Too many requests. Please wait a few seconds before searching again.');
        // Schedule a retry after 3 seconds on rate limit
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => performSearch(query), 3000);
      } else {
        setError(`An error occurred while fetching the data: ${err.message}`);
      }
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => performSearch(query), 1000), // Increased to 1 second
    []
  );

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchTerm(query);
    
    // Clear any pending timeouts
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    if (query.length >= 2) {
      debouncedSearch(query);
    } else {
      setSearchResults([]);
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const handleCloseDialog = () => {
    setSelectedCompany(null);
  };

  const renderCompanyInfo = (company) => {
    return (
      <div>
        <Typography><strong>Name:</strong> {company.name}</Typography>
        <Typography><strong>City:</strong> {company.city}</Typography>
        <Typography><strong>Country:</strong> {company.country}</Typography>
        <Typography><strong>Website:</strong> {company.website}</Typography>
        <Typography><strong>LinkedIn:</strong> {company.linkedin}</Typography>
      </div>
    );
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axiosInstance.get('https://api.yzfdev.com/accounts/user/');
      } catch (error) {
        if (error.response?.status === 403) {
          navigate('https://yzfdev.com/login');
        }
      }
    };
    checkAuth();
  }, [navigate]);

  return (
    <Container maxWidth="md">
      <Box sx={{ 
        mt: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Box sx={{ alignSelf: 'flex-start', mb: 3, width: '100%' }}>
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon />}
            sx={{
              minWidth: 'auto',
              borderRadius: '50px',
              padding: '8px 16px',
              color: theme.palette.secondary.main,
              textTransform: 'none',
              fontSize: '0.95rem',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: `${theme.palette.secondary.main}10`,
                transform: 'translateX(-2px)',
                transition: 'all 0.2s ease-in-out'
              }
            }}
          >
            Back
          </Button>
        </Box>

        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          align="center"
          sx={{ mb: 4 }}
        >
          Database Search
        </Typography>

        <Paper
          component="form"
          sx={{ 
            p: '4px',
            display: 'flex',
            alignItems: 'center', 
            width: '100%',
            mb: 4,
            borderRadius: '12px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          <InputBase
            sx={{ 
              ml: 2, 
              flex: 1,
              fontSize: '1rem',
              '& input': {
                padding: '12px 0'
              }
            }}
            placeholder="Search companies..."
            inputProps={{ 'aria-label': 'search companies' }}
            value={searchTerm}
            onChange={handleSearch}
          />
          <Button 
            sx={{ 
              p: '10px',
              color: theme.palette.primary.main,
              '&.Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.26)'
              }
            }} 
            aria-label="search"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : <SearchIcon />}
          </Button>
        </Paper>

        {/* Search Results */}
        <List sx={{ width: '100%', p: 0 }}>
          {searchResults.map((company) => (
            <ListItem 
              key={company.id} 
              button 
              onClick={() => handleCompanyClick(company)}
              sx={{ 
                mb: 2, 
                bgcolor: 'background.paper', 
                borderRadius: '12px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                transition: 'all 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.12)'
                }
              }}
            >
              <ListItemText 
                primary={
                  <span dangerouslySetInnerHTML={{ 
                    __html: typeof (company.highlights?.name || company.name) === 'string' 
                      ? (company.highlights?.name || company.name) 
                      : 'Unknown Company'
                  }} />
                } 
                secondary={`${company.city || 'Unknown City'}, ${company.country || 'Unknown Country'}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Company Details Dialog */}
      <Dialog 
        open={Boolean(selectedCompany)} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Fade}
        TransitionProps={{
          timeout: {
            enter: 400,
            exit: 400
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '24px',
            opacity: 1,
            transform: 'scale(1)',
            transition: 'all 0.4s ease-in-out',
            '&.MuiDialog-paper-enter': {
              opacity: 0,
              transform: 'scale(0.95)'
            },
            '&.MuiDialog-paper-exit': {
              opacity: 0,
              transform: 'scale(0.95)'
            }
          }
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(4px)',
            transition: 'all 0.4s ease-in-out'
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 2,
          color: theme.palette.primary.main,
          fontWeight: 600,
          fontSize: '1.5rem'
        }}>
          Company Details
        </DialogTitle>

        <DialogContent sx={{ pt: '8px !important' }}>
          {selectedCompany && (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 2 
            }}>
              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary" 
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  Name
                </Typography>
                <Typography variant="body1">
                  {selectedCompany.name}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary" 
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  Location
                </Typography>
                <Typography variant="body1">
                  {`${selectedCompany.city || 'Unknown City'}, ${selectedCompany.country || 'Unknown Country'}`}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary" 
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  Website
                </Typography>
                <Typography variant="body1">
                  {selectedCompany.website || 'N/A'}
                </Typography>
              </Box>

              <Box>
                <Typography 
                  variant="subtitle2" 
                  color="text.secondary" 
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  LinkedIn
                </Typography>
                <Typography variant="body1">
                  {selectedCompany.linkedin || 'N/A'}
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button 
            onClick={handleCloseDialog}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '12px',
              textTransform: 'none',
              px: 4,
              py: 1,
              fontSize: '0.95rem',
              fontWeight: 500,
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
                transform: 'translateY(-1px)',
                boxShadow: '0 4px 12px rgba(85, 122, 89, 0.25)'
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default HomePage;