import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Box, CircularProgress, Alert } from '@mui/material';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const LinkedInScraper = () => {
  const [linkedinUsername, setLinkedinUsername] = useState('');
  const [linkedinPassword, setLinkedinPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    const checkAuth = async () => {
      try {
        await axiosInstance.get('https://api.yzfdev.com/accounts/user/');
      } catch (error) {
        if (error.response?.status === 403) {
          navigate('/login');
        }
      }
    };
    checkAuth();
  }, [navigate]);

  const handleScrape = async (e) => {
    e.preventDefault();
    console.log('Scrape button clicked');
    setLoading(true);
    setError('');
    
    try {
      console.log('Making request...');
      const response = await axiosInstance({
        method: 'post',
        url: 'https://api.yzfdev.com/api/companies/scrape_linkedin/',
        data: {
          linkedin_username: linkedinUsername,
          linkedin_password: linkedinPassword,
          company_name: companyName,
          company_url: companyUrl,
        },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      
      console.log('Response:', response);
      if (response.data && typeof response.data === 'object') {
        setCompanyInfo(response.data);
      }
    } catch (error) {
      console.error('Full error:', error);
      if (error.response?.status === 403) {
        setError('Please log in to use this feature');
        navigate('/login');
      } else {
        setError(error.response?.data?.error || 'An error occurred while scraping');
      }
    } finally {
      setLoading(false);
    }
};

  const renderCompanyInfo = () => {
    if (!companyInfo || typeof companyInfo !== 'object') {
      return <Typography color="error">Invalid company data</Typography>;
    }

    const fields = [
      { label: 'Name', value: companyInfo.Name },
      { label: 'Website', value: companyInfo.Website },
      { label: 'Description', value: companyInfo.Description },
      { label: 'Area', value: companyInfo.Area },
      { label: 'Founded', value: companyInfo.Founded },
      { label: 'Headquarters', value: companyInfo.Headquarters }
    ];

    return (
      <Box sx={{ 
        backgroundColor: 'background.paper',
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        p: 3
      }}>
        {fields.map(({ label, value }) => (
          <Box key={label} sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {label}
            </Typography>
            <Typography variant="body1">
              {value || 'N/A'}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{ 
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          opacity: 1,
          transform: 'translateY(0)',
          animation: 'fadeIn 0.3s ease-out',
          '@keyframes fadeIn': {
            from: {
              opacity: 0,
              transform: 'translateY(10px)'
            },
            to: {
              opacity: 1,
              transform: 'translateY(0)'
            }
          }
        }}
      >
        <Box sx={{ alignSelf: 'flex-start', mb: 3 }}>
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon />}
            sx={{
              minWidth: 'auto',
              borderRadius: '50px',
              padding: '8px 16px',
              color: '#68846D',
              textTransform: 'none',
              fontSize: '0.95rem',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'rgba(104, 132, 109, 0.08)',
                transform: 'translateX(-2px)',
                transition: 'all 0.2s ease-in-out'
              }
            }}
          >
            Back
          </Button>
        </Box>

        <Typography variant="h4" component="h1" gutterBottom align="center">
          LinkedIn Scraper
        </Typography>

        <Box sx={{ 
          width: '100%',
          maxWidth: 600,
          mt: 4
        }}>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              margin="normal"
              label="LinkedIn Username"
              value={linkedinUsername}
              onChange={(e) => setLinkedinUsername(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                }
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="LinkedIn Password"
              type="password"
              value={linkedinPassword}
              onChange={(e) => setLinkedinPassword(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                }
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                }
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Company URL (Optional)"
              value={companyUrl}
              onChange={(e) => setCompanyUrl(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                }
              }}
            />
            <Button
              type="submit"
              variant="contained"
              onClick={handleScrape}
              disabled={loading}
              sx={{
                mt: 3,
                py: 1.5,
                px: 4,
                borderRadius: '12px',
                backgroundColor: '#68846D',
                fontSize: '1rem',
                fontWeight: 600,
                textTransform: 'none',
                boxShadow: '0 4px 18px rgba(104, 132, 109, 0.15)',
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: '#526A56',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 6px 20px rgba(104, 132, 109, 0.2)'
                },
                '&:disabled': {
                  backgroundColor: 'rgba(104, 132, 109, 0.6)',
                }
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
              ) : (
                'Scrape Company Info'
              )}
            </Button>
          </Box>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>
          )}
          {companyInfo && (
            <Box mt={4}>
              <Typography variant="h5" gutterBottom>
                Company Information
              </Typography>
              {renderCompanyInfo()}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LinkedInScraper;